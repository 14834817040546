import { useStaticQuery, graphql } from 'gatsby'

const useSiteMetadata = () => {
  const result = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteTitle
          siteUrl
          siteCover
          defaultCard
          timeToReadEnabled
          postFlagsEnabled
          authorName
          authorAvatar
          authorDescription
          siteDescription
          #twitterUsername
          #disqusShortname
          #disqusSiteUrl
          basicsCourses {
            signupUrl
            notifyUrl
          }
          defaultLang
          headerTitle
          headerSubtitle
          headerLinksIcon
          headerLinks {
            label
            url
          }
          websiteHost {
            name
            url
          }
          footerLinks {
            sectionName
            links {
              label
              url
            }
          }
        }
      }
    }
  `)
  return result.site.siteMetadata
}

export default useSiteMetadata
