import React from 'react';
import styled from 'styled-components'
import {Field, Form, Formik} from 'formik';
import * as yup from 'yup';
import {media} from '../tokens';
import Obfuscate from 'react-obfuscate';
import axios from 'axios';
import {trackCustomEvent} from 'gatsby-plugin-google-analytics'

const year_now = new Date().getFullYear();

const kotr_enroll_schema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  birth_year: yup.number().required().min(1940).max(year_now).integer(),
  email: yup.string().required().email(),
  phone: yup.string().required(),
  city: yup.string().min(2).required(),
  postal_code: yup.string().required().length(5).matches(/[0-9]+/gi),
  course: yup.string().required(),
  username: yup.string(),
  meta: yup.string(),
});

const Label = styled.label`
  display: block;
  font-weight: bold;
  font-size: 0.8rem;
`;

const FormRow = styled.div`
  margin-top: 1rem;
  
  input {
    font-size: 1.1rem;
    display: block;
    border: 0;
    border-bottom: 2px solid #ccc;
    width: 100%;
    outline: none;
    &:focus {
      border-color: ${props => (props.touched && !props.error) ? 'green' : '#699ef4'};
    }
    
  }
  label {
    color: ${props => !props.touched ? '#333'
  : props.error ? 'red'
    : 'green'}
  }
`;

const Submit = styled.button`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: inline-block;
  border: none;
  padding: 0.5rem;
  font-size: 1.1rem;
  width: 100%;
  outline: none;
  
  background-color: #80ea9c;
  color: #1a3f00;
  
  &.focus {
    border: 2px solid #46cf3b;
    &[disabled] {
      border: #ccc;
    }
  }
  
  &[disabled] {
    background-color: rgb(245,245,245);
    color: #aaa;
  }
`;


const TextField = ({errors, touched, name, children}) => {
  return (
    <FormRow error={errors[name]} touched={touched[name]}>
      <Label htmlFor={name}>{children}</Label>
      <Field name={name} id={name}/>
      { /* errors[name] && touched[name] ? (
        <div>{errors[name]}</div>
      ) : null */}
    </FormRow>
  )
}

const StyledForm = styled(Form)`
  margin-top: 2rem;
  display: block;
  width: 100%;
  @media ${media.medium} {
    max-width: 22rem;
    margin-left: auto;
    margin-right: auto;
  }
`

const FormHeader = styled.div`
  p {
    margin-top: 0.5rem;
  }
`

const SubmitError = styled.div`
  margin-top: 0.5rem;
  p {
    margin-top: 0.5rem;
  }
  font-size: 0.9rem;
  color: #e80000;
`

const SignupForm = props => {

  const course = props.course || 'intro-2020-11';

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        birth_year: '',
        email: '',
        phone: '',
        city: '',
        postal_code: '',
        course: course,
        meta: 'ohai',
        username: '',
      }}
      initialStatus={{}}
      validateOnMount={true}
      validationSchema={kotr_enroll_schema}
      onSubmit={async (values, actions) => {
        // same shape as initial values
        values.course = course;
        //delete values.username;
        console.log('SUBMITTING', values);

        try {
          const URL = 'https://cf.scdr.workers.dev/enroll/'
          const response = await axios.post(URL, values);
          console.log(response.data);

          trackCustomEvent({
            category: "Signup form",  // required
            action: "Submit", // required
          });

          if(response.data.success !== true) {
            throw Error("Unexpected response from the server!");
          }
          /**
           * Successful response: 200, {success: true}
           * Bot check response: 200, {success: "Nah, not really"}
           * Error response: 40x, {success: false, errors: ["Field postal_code must be exactly 5 numbers long."]}
           */

          // Handle response
          actions.setStatus({
            success: true,
            error: null,
          });

        } catch(err) {
          console.error(err);
          actions.setStatus({error: true, success: false})
        }

        // await new Promise((r) => setTimeout(r, 1000));
      }}
    >
      {({errors, touched, isValid, isSubmitting, status, setStatus, handleReset}) => (
        <>
          {status.success ? (
            <FormHeader style={{textAlign: "center", marginTop: "50px", marginBottom: "50px"}}>
              <h2 style={{marginTop: "50px", marginBottom: "25px",}}>
                Kiitos!
                {/* <Submit onClick={handleReset}>Takaisin ilmoitatutumislomakkeeseen</Submit>*/}
              </h2>

              <p>
                Ilmoittautumisesi on tallennettu, nähdään salilla!
              </p>
              <p>
                <a onClick={() => {setStatus({}); handleReset()}}>Ilmoita toinen osallistuja</a>
              </p>

              <h2 style={{marginTop: "50px", marginBottom: "25px",}}>
                Thank you!
              </h2>
              <p>
                Your enrolment has been registered, see you at the gym!
              </p>

              <p>
                <a onClick={() => {setStatus({}); handleReset();}}>Enrol another person</a>
              </p>
              { /* <Submit onClick={handleReset}>Ilmoita toinen osallistuja / Enrol another person</Submit> */}
            </FormHeader>

          ) : (
            <>
              <FormHeader>
                <h2>Henkilötiedot / Participant information</h2>

                <small>
                  <p>
                    Ilmoittaudu peruskurssille lähettämällä lomakkeessa pyydetyt tiedot. Tietoja käytetään vain
                    jäsenrekisterin ylläpitämiseen sekä kursseihin liittyvään viestintään, eikä niitä välitetä
                    kolmansille
                    osapuolille.
                  </p>

                  <p>
                    Please enrol to the course by filling in the form below. Information provided is only used to
                    maintain a list of participants and for course related communication, and is never shared with third
                    parties.
                  </p>
                </small>
              </FormHeader>

              <StyledForm>
                <TextField name="first_name" errors={errors} touched={touched}>
                  Etunimi / First Name
                </TextField>

                <TextField name="last_name" errors={errors} touched={touched}>
                  Sukunimi / Surname
                </TextField>

                <TextField name="birth_year" errors={errors} touched={touched}>
                  Syntymävuosi / Year of birth
                </TextField>

                <TextField name="email" type="email" errors={errors} touched={touched}>
                  Sähköposti / Email
                </TextField>

                <TextField name="phone" errors={errors} touched={touched}>
                  Puhelinnumero / Phone number
                </TextField>

                <TextField name="city" errors={errors} touched={touched}>
                  Kaupunki / City
                </TextField>

                <TextField name="postal_code" errors={errors} touched={touched}>
                  Postinumero / Postal number
                </TextField>

                <Field name="meta" type="hidden"/>
                <Field name="username" type="hidden"/>

                {status.error ? (
                  <SubmitError>
                    <p>Tietojen lähettäminen epäonnistui! Tarkista lomakkeen tiedot ja kokeile uudestaan!</p>
                    <p>Form submission failed! Please check the data entered and try again!</p>
                  </SubmitError>
                ) : null}

                <Submit type="submit" isValid={isValid} disabled={isSubmitting || !isValid}>
                  {isSubmitting ? '...' : 'Lähetä / Submit'}
                </Submit>

                <FormHeader>
                  <small style={{paddingTop: "1rem"}}>
                    <p>
                      Jos kohtaat ongelmia rekisteröitymisen kanssa, otathan yhteyttä osoitteeseen <Obfuscate
                      email="contact@kingofthering.fi"/>.
                    </p>
                    <p>
                      If you experience problems signing up, please contact us at <Obfuscate
                      email="contact@kingofthering.fi"/>.
                    </p>
                  </small>
                </FormHeader>
              </StyledForm>
            </>
          )}
        </>
      )}
    </Formik>
  );
};

export default styled(SignupForm)`
`;
