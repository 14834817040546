import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Header from './Header'
import Footer from './Footer'
import 'prismjs/themes/prism-tomorrow.css'
import { GlobalStyle } from './Commons'
import { media } from '../tokens'
import { MDXProvider } from "@mdx-js/react"
import useSiteMetadata from "../hooks/use-site-config";

//import { ImageFrame } from '../src/components/ImageFrame';
//const shortcodes = {ImageFrame};

const SiteContent = styled.div`
  margin: 0 0;

  @media ${media.medium} {
    margin: 60px 0;
  }
  
  .gatsby-resp-image-figure {
    background-color: rgba(0,0,0,0.1);
    padding: 0.2rem;
    float: left;
    display: inline-block;
  }
  
  .gatsby-resp-image-wrapper {
    flex-grow: 0;
    flex-shrink: 0;
  }
  
  .gatsby-resp-image-figcaption {
    font-size: 0.8rem;
    display: block;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 1;
    overflow: hidden;
    padding: 0.1rem 0.4rem 0.4rem 0.4rem;
  }
`

// Make some elements available to all MDX pages
import Obfuscate from 'react-obfuscate';
import SignupForm from "./SignupForm";

const shortcodes = { Obfuscate, SignupForm };

class Template extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Lato:400,700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <GlobalStyle />
        <Header />
        <SiteContent>
          <MDXProvider components={shortcodes}>
            {children}
          </MDXProvider>
        </SiteContent>
        <Footer />
      </>
    )
  }
}

export default Template
